.fc-col-header-cell-cushion {
    color: #000;
}

.fc-daygrid-day-number {
    color: #000;
}

.fc-event-title {
    font-weight: bold;
}
